import React from "react"
import "../styles/index.scss";

function RecommendationsQAPage() {
  return (
    <iframe
    src="https://firebasestorage.googleapis.com/v0/b/sommer-consulting.appspot.com/o/QA%20-%20Preparation%20materials%20-%20SOMMER.pdf?alt=media&token=8d0fe883-de32-4d5a-b449-76fe785153cd"
    style={{width: "100%", height: "100%"}}
    ></iframe>
  )

}

export default RecommendationsQAPage